import React from "react";
import MUIBaseline from "@material-ui/core/CssBaseline";
import Theme from "./app/theme/muiTheme";
import Config from "./app/index";

// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const App = () => {
  return (
    <Theme>
      <MUIBaseline />
      {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}

      <Config />

      {/* </MuiPickersUtilsProvider> */}
    </Theme>
  );
};
export default App;
