import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Also see /css/px-bootstrap.scss
const PrimaryLight = "#ffffff";
const PrimaryDark = "#1D1D1F";
export const Primary = "#2BBB94";

const Secondary = "#62A9BA";
const BodyColor = "#E0E0E0";
const SecondaryText = "#707070";
const BodyBG = "#ffffff";
export const FEEDBACK_ICON_COLOR = "#F0BF34";
export const PRIMARY_FONT = "Inter";
export const SECONDARY_FONT = "Lora";
export const TERTIARY_FONT = "Arimo";
export const EXTRA_FONT = "Fjalla One";
export const FORMA_DJR_DECK = "forma-djr-deck";
export const APPBARHEIGHT = 80;

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      root: {
        color: Primary,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: "white",
      },
    },
    MuiAlert: {
      standardWarning: {
        color: "#ffffff",
        backgroundColor: "#ff9800",
        fontWeight: 500,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white",
      },
      toolbarBtnSelected: {
        color: "white",
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: Primary,
          color: PrimaryDark,
        },
      },
      root: {
        color: PrimaryDark,
        "&$selected": {
          backgroundColor: Primary,
          color: PrimaryDark,
          "&:hover": {
            backgroundColor: Primary,
            color: PrimaryDark,
          },
        },
        "&:hover": {
          backgroundColor: PrimaryLight,
          color: PrimaryDark,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        "&.label-large": {
          fontSize: "1rem",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: Primary,
        textTransform: "none",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: Secondary,
          "@media (hover: none)": {
            color: BodyColor,
          },
        },
        "&.Mui-disabled": {
          color: "white",
        },
      },

      textPrimary: {
        color: "white",
        "&:hover": {
          color: Primary,
        },
      },
      label: {},
    },
    MuiAppBar: {
      colorPrimary: { color: "#707070", backgroundColor: "#FFFFFF" },
      positionFixed: {
        "&.secondary-bar": {
          zIndex: 1099,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "red",
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-child(odd)": {
          backgroundColor: "rgba(0, 0, 0, 0.03)",
        },
      },
    },
    MuiTablePagination: {
      caption: {
        color: "black",
      },
      select: {
        color: "black",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 0px 4px 24px",
      },
      head: {
        color: "black",
        fontWeight: "bold",
      },
      body: {
        color: "black",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#62A9BA",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 45,
        "& fieldset": {
          borderWidth: 1,
          borderColor: Secondary,
          borderStyle: "solid",
        },
        "&:hover fieldset": {
          borderWidth: 1,
          borderColor: Secondary,
          borderStyle: "solid",
          outline: 0,
        },
      },
      input: {
        fontFamily: TERTIARY_FONT,
      },
      notchedOutline: {
        borderColor: Secondary,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "white",
        color: "black",
      },
      input: {
        "$ :focus": {
          outline: 2,
        },
      },
    },
    MUIDataTableHeadCell: {
      toolButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      sortAction: {
        height: "auto",
      },
    },
    MUIDataTableBodyCell: {
      cellStacked: {
        "@media (max-width: 961px)": {
          width: "30% !important",
          height: "30% !important",
          borderBottomWidth: "0px !important",
          backgroundColor: "transparent !important",
          paddingBottom: 0,
          paddingTop: "15px",
          color: "#999",
          fontSize: 12,
        },
      },
      responsiveStacked: {
        "@media (max-width: 961px)": {
          width: "70% !important",
          height: "70% !important",
          overflowX: "scroll",
          paddingTop: "15px",
          borderBottomWidth: 0,
          "& .table-actions": {
            marginTop: -20,
          },
        },
      },
    },
    MUIDataTableFilter: {
      selectFormControl: {
        flex: "1 1 200px",
        marginRight: 0,
      },
    },
    MuiTextField: {},
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 1200,
      xl: 1300,
    },
  },
  DrawerWidth: 237,
  calendarCellWidth: 75,
  APPBARHEIGHTLG: 80,
  APPBARHEIGHTMD: 80,
  APPBARHEIGHTSM: 80,
  palette: {
    primary: {
      main: Primary,
      contrastText: "#000",
      light: PrimaryLight,
      dark: PrimaryDark,
    },
    secondary: {
      main: Secondary,
      dark: "#2BBB94",
      contrastText: "#fff",
    },

    background: {
      default: BodyBG,
      lightblue: "#D4DBCD",
      lightgrey: "#A5A5A5",
      lightgrey_secondary: "#EAEAEA",
      lighgrey_tertiary: "#D8D9D8",
      textfield: "#F8F8F8",
      darkgrey: "#393939",
      dark: "#2E2E2E",
      black: "#2E2E2E",
      green: "#006459",
    },
    text: {
      primary: BodyColor,
      secondary: SecondaryText,
      tertiary: PrimaryLight,
      extra: "#E0E0E0",
      primary1: "#2E2E2E",
      primary2: "#615A5A",
      error: "#DD5C89",
      darkwhite: "#EBEAEA",
      dark: "#1D1D1F",
    },
    borders: {
      primary: "#BCB7B7",
    },
  },
  typography: {
    fontFamily: PRIMARY_FONT,
    fontFamilyLora: SECONDARY_FONT,
    fontFamilyArimo: TERTIARY_FONT,
    fontFamilyFjalla: EXTRA_FONT,
    fontFamilyForma: FORMA_DJR_DECK,
    useNextVariants: true,
  },
});

class PXMaterialUITheme extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
    );
  }
}

export default PXMaterialUITheme;
